import { gsap } from "gsap";

export default function FormPopup(els) {
  const form = document.querySelector(".webinar-form");
  let isFormOpen = form ? !form.classList.contains("hidden") : false;

  if (!form) {
    console.error("Form does not exist");
    return;
  } else {
    console.log("form found");
  }

  function lockScreen() {
    if (isFormOpen) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }

  lockScreen();

  els.forEach((el) =>
    el.addEventListener("click", () => {
      if (!isFormOpen) {
        isFormOpen = true;
        form.classList.remove("hidden");
        gsap.fromTo(form, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isFormOpen = false;
        gsap.fromTo(
          form,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => form.classList.add("hidden"),
          }
        );
      }
      lockScreen();
    })
  );
}
