import xor from "lodash/xor";
import gsap from "gsap";

export default function ResourceFilter(el) {
  const buttons = [...el.querySelectorAll(".selectionForm .categoryButton")];
  const listing = el.querySelector("[data-listing]");
  const loadMore = el.querySelector("[data-load-more]");
  const subCategoryButtons = el.querySelectorAll(
    'button[name="eventsSubcategory"]'
  );
  const url = el.dataset.url;

  let currentPage = +el.dataset.current;
  let currentTotal = +el.dataset.total;
  let totalPages = +el.dataset.totalPages;
  let pageSize = +el.dataset.pagesize;
  let cats = el.dataset.cat ? el.dataset.cat.split(",") : [];
  let eventsSubcategory = "";
  subCategoryButtons.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      if (eventsSubcategory === btn.value) {
        eventsSubcategory = "";
        btn.classList.remove("selected");
      } else {
        eventsSubcategory = btn.value;
        subCategoryButtons.forEach((btn) => btn.classList.remove("selected"));
        btn.classList.add("selected");
      }
      update();
    });
  });

  el.addEventListener("submit", (e) => {
    e.preventDefault();
  });

  buttons.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      currentPage = 1;

      // buttons[0].classList.remove("selected");
      buttons.forEach((btn) => btn.classList.remove("selected"));

      if (btn.value == "" || cats.includes(btn.value)) {
        buttons[0].classList.add("selected");
        cats = [];
      } else {
        btn.classList.add("selected");
        cats = [btn.value];
      }

      if (btn.value === "1387") {
        eventsSubcategory = "";
        subCategoryButtons.forEach((btn) => {
          btn.classList.remove("hidden");
        });
      } else {
        subCategoryButtons.forEach((btn) => btn.classList.add("hidden"));
      }

      if (cats.length === 0)
        buttons.find((btn) => btn.value === "").classList.add("selected");

      update();
    });
  });

  loadMore.addEventListener("click", (e) => {
    e.preventDefault();
    currentPage++;
    update();
  });

  function update() {
    listing.style.opacity = "0.5";

    const params = new URLSearchParams();
    params.append("page", currentPage);
    params.append("pagesize", pageSize);
    params.append("eventsSubcategory", eventsSubcategory);
    cats.forEach((cat) => params.append("cat[]", cat));

    fetch(`${url}?${params.toString()}`, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    }).then((r) => {
      currentPage = +r.headers.get("Currentpage");
      currentTotal = +r.headers.get("Total");
      totalPages = +r.headers.get("Totalpages");

      if (currentPage > totalPages) {
        loadMore.classList.add("hidden");
      } else {
        loadMore.classList.remove("hidden");
      }

      r.text().then((html) => {
        listing.innerHTML = html;
        listing.style.opacity = "1";
      });
    });
  }
}
