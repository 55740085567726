export default function VidWrapper({ children }) {
  return (
    <div
      class="m-auto flex-1 overflow-hidden rounded-md w-full  max-w-full lg:rounded-[20px] 2xl:rounded-[40px] relative border border-[#18AEAD] shadow-lg"
      style="box-shadow: 1px 1px 7px #18AEAD40;"
    >
      <div
        class="relative w-full z-10 pb-[47%]"
        style="background-image: url(/load.gif); background-size: cover; background-position: center;"
      >
        {children}
      </div>
    </div>
  );
}
