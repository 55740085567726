import eventBus from "./utils/eventBus";
import { render } from "preact";
import MultiSelect from "./Components/MultiSelect";
import DateFilter from "./Components/DateFilter";
import { useState } from "preact/hooks";
import Flickity from "flickity";

function FilterComponent({ categories, dates, tags, getResults, elementId }) {
  let filter = {};
  const getKey = () => Math.ceil(Math.random() * 100);
  const [key, setKey] = useState(getKey());
  loadFilterUrl();

  function update(newFilter) {
    filter = {
      ...filter,
      ...newFilter,
    };

    getResults(filter);
    generateFilterUrl();
  }

  function clear() {
    setKey(getKey());
    update({
      start: undefined,
      end: undefined,
      classesCategory: categories.map((c) => c.id),
    });

    const url = window.location.pathname;
    window.history.pushState({}, "", url);
  }

  function generateFilterUrl() {
    const params = new URLSearchParams();

    if (filter.start) params.append("start", filter.start.toDateString());
    if (filter.end) params.append("end", filter.end.toDateString());
    (filter?.topic ?? []).forEach((topic) => params.append("topic[]", topic));
    (filter?.classesCategory ?? []).forEach((cat) =>
      params.append("cat[]", cat)
    );
    params.append("id", elementId);

    const url = `${window.location.pathname}?${params.toString()}#${elementId}`;
    window.history.pushState({}, "", url);
  }

  function loadFilterUrl() {
    if (elementId !== window.location.search.split("id=")[1]) return;

    const params = new URLSearchParams(window.location.search);
    const start = params.get("start");
    const end = params.get("end");
    const topic = params.getAll("topic[]");
    const classesCategory = params.getAll("cat[]");

    update({
      start: start ? new Date(start) : undefined,
      end: end ? new Date(end) : undefined,
      topic,
      classesCategory,
    });
  }

  return (
    <div class="flex justify-center items-center flex-wrap">
      {categories.length > 1 && (
        <MultiSelect
          options={categories}
          placeholder="Filter by category"
          className="mb-1 md:m-2"
          onChange={(val) => update({ classesCategory: val.map((c) => c.id) })}
          key={key}
          value={filter.classesCategory}
        />
      )}

      {dates.length > 0 && (
        <DateFilter
          dates={dates}
          className="mb-2 md:m-2"
          onChange={(start, end) => update({ start, end })}
          key={key}
          startDate={filter.start}
          endDate={filter.start}
        />
      )}

      <MultiSelect
        options={tags}
        placeholder="Filter by tags"
        className="mb-2 md:m-2"
        onChange={(val) => update({ topic: val.map((c) => c.id) })}
        key={key}
        value={filter.topic}
      />

      <button
        class="px-5 py-1 shadow rounded-lg bg-white hover:bg-gray-100 h-[50px]"
        onClick={clear}
      >
        Clear
      </button>
    </div>
  );
}

export default function LessonFilter(el) {
  const filterEl = el.querySelector("[data-filter]");
  const categories = JSON.parse(el.dataset.categories);
  const tags = JSON.parse(el.dataset.tags);
  const dates = JSON.parse(el.dataset.dates).map((d) => d.split(" ")[0]);
  const url = el.dataset.url;
  const useListView = el.dataset.useListView;
  let flkty;

  const App = (
    <FilterComponent
      categories={categories}
      dates={dates}
      tags={tags}
      getResults={getResults}
      elementId={el.id}
    />
  );

  function getResults(filter) {
    const params = new URLSearchParams();

    if (filter.start) params.append("start", filter.start.toDateString());
    if (filter.end) params.append("end", filter.end.toDateString());
    (filter?.topic ?? []).forEach((topic) => params.append("topic[]", topic));
    (filter?.classesCategory ?? []).forEach((cat) =>
      params.append("cat[]", cat)
    );
    params.append("useListView", useListView);
    console.log(useListView);

    el.style.opacity = "0.5";

    if (flkty) {
      flkty.destroy();
    }

    fetch(`${url}?${params.toString()}`, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((r) => r.text())
      .then((html) => {
        const elementTarget = useListView ? ".dataRoot" : ".flickity-slider";

        const slider = el.querySelector(elementTarget);
        slider.innerHTML = html;
        el.style.opacity = "1";

        if (!html.includes("no-results") && !useListView) {
          eventBus.emit("slider:reinit");
        }
      });
  }

  render(App, filterEl);
}
