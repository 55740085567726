import Flickity from "flickity-fade";
import eventBus from "./utils/eventBus";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  let flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  function prevSlide() {
    flk.previous();
  }

  document
    .querySelectorAll(options?.nextButton)
    .forEach((el) => el.addEventListener("click", nextSlide));

  document
    .querySelectorAll(options?.prevButton)
    .forEach((el) => el.addEventListener("click", prevSlide));

  document.addEventListener("lazyloaded", (e) => {
    flk.resize();
  });

  function setActiveBtn(btns, index) {
    btns.forEach((btn) => btn.classList.remove("active"));
    btns[index].classList.add("active");
  }

  function setActiveDot(dots, index) {
    dots.forEach((dot) => dot.classList.remove("active"));
    dots[index].classList.add("active");
  }

  if (options.customNav) {
    const btns = [...document.querySelectorAll(options.customNav + " .button")];
    const dots = [
      ...document.querySelectorAll(options.customNav + " .map-dot"),
    ];

    btns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        const index = btns.indexOf(btn);
        flk.select(index);
      });
    });

    dots.forEach((dot) => {
      dot.addEventListener("click", (e) => {
        const index = dots.indexOf(dot);
        flk.select(index);
      });
    });
    setActiveBtn(btns, 0);
    setActiveDot(dots, 0);

    flk.on("change", (index) => {
      setActiveBtn(btns, index);
      setActiveDot(dots, index);
    });
  }

  eventBus.on("slider:reinit", () => {
    flk.destroy();
    flk = new Flickity(el, options);
  });

  return () => {
    removeEvents(nextButton, prevButton);
    flk.destroy();
  };
}

export default Slider;
