export default function CustomerReferences(el) {
    const seeMoreBtn = el.querySelector("#see-more");
    let offset = parseInt(seeMoreBtn.dataset.offset);
    const total = parseInt(seeMoreBtn.dataset.total);
    if (offset >= total) {
        seeMoreBtn.disabled = true;
        return;
    }
    seeMoreBtn.addEventListener("click", function () {
        const currentOffset = parseInt(seeMoreBtn.dataset.offset);
        fetch(`/api/customer-references?offset=${currentOffset}`).then(response => response.text()).then(data => {
            if (currentOffset + 3 >= total) {
                seeMoreBtn.disabled = true;
            }
            el.querySelector("#reference-list").insertAdjacentHTML("beforeend", data);
            seeMoreBtn.setAttribute("data-offset", currentOffset + 3);
        });
    });
}