import { render, Fragment } from "preact";
import clsx from "clsx";

const VidLink = ({ i, onClick, children, active = false }) => (
  <Fragment key={i}>
    <>
      {i > 0 && (
        <div class="hidden 2xl:grid w-5 justify-center">
          <div class="w-[2px] h-[2px] mb-3 rounded-full bg-purple"></div>
        </div>
      )}
      <button
        onClick={onClick}
        class={clsx(
          "lg:flex rounded-md grid items-center transition-all",
          active ? "font-bold" : "opacity-80"
        )}
      >
        <div class="w-5 h-5 hidden lg:block rounded-full">
          <img
            class="w-full lazyload h-full"
            data-src="/images/assets/tick.svg"
            alt="tick"
          />
        </div>
        <p class="flex-1 rounded-md border border-purple lg:border-none lg:mx-2 grid content-center text-[12px] md:text-[14px] lg:text-left px-2 py-1 sm:py-2 bg-purple-light lg:bg-opacity-0 xl:min-w-[200px] mb-0 text-center">
          {children}
        </p>
      </button>
    </>
  </Fragment>
);

export default VidLink;
