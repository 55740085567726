export default function Show(els) {
  const show = document.querySelector(".show-item");

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      show.classList.toggle("hidden");
    })
  );
}
