import gsap from "gsap/dist/gsap";
import DrawSVGPlugin from "gsap/dist/DrawSVGPlugin";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

export default function Line(el) {
  const path = el.querySelector("path");
  gsap.set(path, {
    drawSVG: 0,
  });
  gsap
    .timeline({
      scrollTrigger: {
        markers: false,
        trigger: el,
        scrub: true,
        start: "top 0",
        end: "bottom -500px",
      },
    })
    .to(path, 50, {
      drawSVG: "100%",
    });
}
