export default function orphan() {
  const els = document.querySelectorAll(".no-orphan");

  els.forEach((el) =>
    el.querySelectorAll("h1, h2, strong, p, h3").forEach((p) => {
      const splitText = p.innerHTML.split(" ");
      const end = splitText.pop();
      p.innerHTML = splitText.join(" ") + "&nbsp;" + end;
    })
  );
}
