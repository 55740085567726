
var dataLayer = [];
const loadGTMOnScroll = function() {
  (function(w,d,s,l,i){
      console.log('loading GTM')
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l!='dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KQFWQ3JV');
    // Remove the scroll event listener once GTM has loaded
    window.removeEventListener('scroll', loadGTMOnScroll);
};

export default loadGTMOnScroll;