import clsx from "clsx";
import { easepick, RangePlugin } from "@easepick/bundle";
import { useRef, useEffect } from "preact/hooks";

function DateFilter({ dates = [], className, onChange, startDate, endDate }) {
  const inputRef = useRef(null);

  function setup(picker, dates) {
    picker.on("view", (evt) => {
      const { view, date, target } = evt.detail;
      const calDate = date ? date.format("YYYY-MM-DD") : null;

      if (view === "CalendarDay") {
        const lessons = dates.filter((date) => date == calDate).length;
        if (lessons) {
          target.innerHTML = `<span style='position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: 1px solid blue;'></span>${target.innerText}`;
        }
      }
    });

    picker.on("select", (e) => {
      const { start, end } = e.detail;
      onChange?.(start, end);
    });
  }

  useEffect(() => {
    const picker = new easepick.create({
      element: inputRef.current,
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css",
      ],
      zIndex: 30,
      format: "DD MMM YYYY",
      grid: 2,
      calendars: 2,
      plugins: [RangePlugin],
      startDate,
      endDate,
      setup: (picker) => setup(picker, dates),
    });

    if (startDate && endDate) {
      picker.setStartDate(startDate);
      picker.setEndDate(endDate);
    }
  }, []);

  return (
    <div class={clsx("flex relative w-[320px] md:w-[250px]", className)}>
      <input
        ref={inputRef}
        placeholder="Filter by date"
        class="border px-3 md:px-5 py-2 md:py-3 rounded-md md:rounded-lg lg:rounded-xl shadow hover:bg-blue-light focus:bg-blue-light w-full "
      />
      <div class="absolute pointer-events-none mr-4 right-0 top-[31%]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.283"
          height="17.423"
          viewbox="0 0 17.283 17.423"
        >
          <g
            id="Group_592"
            data-name="Group 592"
            transform="translate(-616.737 -3601.563)"
            fill="#FFFFFF7F"
          >
            <rect
              id="Rectangle_156076"
              data-name="Rectangle 156076"
              width="16.283"
              height="15.038"
              rx="2.17"
              transform="translate(617.237 3603.448)"
              fill="white"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_218"
              data-name="Line 218"
              x2="16.283"
              transform="translate(617.237 3607.717)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_219"
              data-name="Line 219"
              x2="2.729"
              transform="translate(619.952 3611.058)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_220"
              data-name="Line 220"
              x2="2.729"
              transform="translate(624.011 3611.058)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_221"
              data-name="Line 221"
              x2="2.729"
              transform="translate(628.07 3611.058)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_222"
              data-name="Line 222"
              x2="2.729"
              transform="translate(619.952 3615.117)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_223"
              data-name="Line 223"
              x2="2.729"
              transform="translate(624.011 3615.117)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_224"
              data-name="Line 224"
              x2="2.729"
              transform="translate(628.07 3615.117)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_225"
              data-name="Line 225"
              y2="4.753"
              transform="translate(621.316 3601.563)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <line
              id="Line_226"
              data-name="Line 226"
              y2="4.753"
              transform="translate(629.442 3601.563)"
              fill="none"
              stroke="#f48e09"
              stroke-miterlimit="10"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
}

export default DateFilter;
