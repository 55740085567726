import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());

  const panels = [...document.querySelectorAll(".panel")];

  panels.forEach((panel, i) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: panel,
          start: `top ${15 + i * 1.5}%`,
          endTrigger: panel.parentElement,
          // fastScrollEnd: true,
          // preventOverlaps: true,
          // pin: true,
          pinSpacing: false,
          scrub: 1,
          markers: false,
        },
      })
      .set(panel, {
        willChange: "transform",
      })
      .to(panel, {
        duration: 0.1,
        y: "-=10%",
        scale: 0.9,
      })
      .to(panel, {
        duration: 0.9,
        delay: ((1 / panels.length) * panels.length - 1) * 1.1,
        opacity: 0,
      });
  });

  const flowChart = document.querySelector(".flow-chart");

  if (flowChart) {
    gsap.timeline({
      scrollTrigger: {
        trigger: ".flow-chart",
        start: "top 70%",
        end: "bottom 60%",
        scrub: 1,
      },
    }).fromTo(".flow-chart .item", {
        boxShadow: "none",
      }, {
        duration: 1,
        // boxShadow: "0px 0px 10px 5px #3330444C",
        boxShadow: "0px 0px 10px 5px #20A9E94C",
        stagger: 0.1,
    });
  } 
}
