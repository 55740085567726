import { useState, useEffect, useRef } from "preact/hooks";
import clsx from "clsx";
import xorBy from "lodash/xorBy";

const inputClass =
  "border px-3 md:px-5 py-2 md:py-3 rounded-md md:rounded-lg lg:rounded-xl shadow hover:bg-blue-light focus:bg-blue-light w-full h-full bg-white overflow-ellipsis";

function Option({ children, onClick, active }) {
  return (
    <label
      onClick={onClick}
      class="flex items-start cursor-pointer p-1 transition-all hover:bg-blue-50 rounded-md "
    >
      <input
        type="checkbox"
        class="w-5 h-5 mr-3 cursor-pointer mt-[3px]"
        checked={active}
      />
      <span class="flex-1">{children}</span>
    </label>
  );
}

function MultiSelect({
  options,
  placeholder = "Filter by",
  className,
  onChange,
  value,
}) {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const selectedDefault = options.filter((o) => value?.includes(String(o.id)));
  const [selected, setSelected] = useState(selectedDefault || []);
  const [mounted, setMounted] = useState(false);
  
  useEffect(() => {
    if (!mounted) setMounted(true);
    
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape") setOpen(false);
    });
    window.addEventListener("click", (e) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target))
      setOpen(false);
  });
}, []);

useEffect(() => {
  if (!mounted) return;
  onChange?.(selected);
  }, [selected]);

  return (
    <div ref={wrapperRef} class={clsx("h-full relative ", className)}>
      <div
        onClick={() => setOpen(!open)}
        class={clsx(
          inputClass,
          "w-[320px] md:w-[250px] cursor-pointer whitespace-nowrap overflow-hidden"
        )}
        tabindex="0"
      >
        {selected.length ? (
          selected.map((o) => o.title).join(", ")
        ) : (
          <span class="text-[#9CA3AF]">{placeholder}</span>
        )}
      </div>

      <div
        style="box-shadow: 0 0 25px rgb(0 0 0 / 30%)"
        class={clsx(
          "absolute top-[101%] md:p-2 bg-white rounded-md border min-w-[200px] w-full z-50 transition-all max-h-[40vh] overflow-y-auto",
          !open && "pointer-events-none opacity-0"
        )}
      >
        {options.map((opt, i) => (
          <Option
            key={opt.id}
            active={selected.find((s) => s.id == opt.id)}
            onClick={() => {
              setOpen(false);
              setSelected(xorBy(selected, [opt], "id"));
            }}
          >
            {opt.title}
          </Option>
        ))}
      </div>
    </div>
  );
}

export default MultiSelect;
