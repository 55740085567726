import { render, Fragment } from "preact";
import { useState, useRef } from "preact/hooks";
import clsx from "clsx";

function VideoCardComponent({ data }) {
  const { heading, subHeading, videos, customText, customUrl } = data;
  const [step, setStep] = useState(0);
  const [fade, setFade] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(100);
  const vidRef = useRef(null);

  const reset = () => {
    setCurrentTime(0);
    setStep(0);
    vidRef.current.currentTime = 0;
  };

  const onEnded = (e) => {
    setTimeout(() => {
      if (step < videos.length - 1) {
        setStep(step + 1);
      } else {
        reset();
      }
    }, 1200);
  };

  const onTimeupdate = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
    if (e.target.currentTime > 0.5) {
      setFade(false);
    }
    if (e.target.currentTime > e.target.duration - 0.5) {
      setFade(true);
    }
  };

  const Bullet = ({ key, active, onClick, children }) => (
    <Fragment key={key}>
      <button
        onClick={onClick}
        class={clsx(
          "button text-left mb-1 md:mb-4 hover:font-bold transition-all duration-100 w-full",
          active && "font-bold"
        )}
      >
        {children}
      </button>
    </Fragment>
  );

  const handleBulletClick = (bullet) => {
    const timestamp = +bullet.timestamp;
    vidRef.current.currentTime = timestamp;
  };

  return (
    <div class="p-5 md:p-10 md:flex">
      <aside class="md:w-1/3 grid content-center">
        <p class="h2 text-[30px] mb-2 leading-[30px] font-bold md:min-h-[60px]">
          {heading}
        </p>
        <p class="font-bold hidden md:block">{subHeading}</p>

        {videos?.[0]?.bullets?.map((bullet, i) => (
          <Bullet
            key={i}
            active={currentTime > +bullet.timestamp}
            onClick={() => handleBulletClick(bullet)}
          >
            {bullet.heading}
          </Bullet>
        ))}

        {customUrl && customUrl && (
          <a
            href={customUrl}
            class="bg-purple w-max hidden md:flex px-3 md:w-max sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-100 hover:shadow-md hover:drop-shadow-md items-center justify-center md:mt-10 mb-5"
          >
            {customText}
          </a>
        )}
      </aside>

      <div
        class={clsx(
          "md:ml-5 m-auto mt-5 md:mt-0 flex-1 overflow-hidden rounded-md relative border-[#18AEAD]"
        )}
        style="box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.13);"
      >
        <div
          class="relative w-full z-10 pb-[47%]"
          style="background-image: url(/load.gif); background-size: cover; background-position: center;"
        >
          <video
            id="custom-video"
            ref={vidRef}
            data-src={videos[step].url}
            class={clsx(
              "w-full h-full lazyload bg-bg absolute left-0 top-0 transition-all duration-100",
              fade && "opacity-0"
            )}
            muted
            autoplay
            onTimeupdate={onTimeupdate}
            onEnded={onEnded}
          >
            Your browser does not support the video tag.
          </video>

          {/* <progress
            class={clsx(
              "absolute left-0 w-full top-0 vid-progress ",
              duration > 1 && "active"
            )}
            value={currentTime}
            max={duration}
          /> */}
        </div>
      </div>
      {customUrl && customUrl && (
        <a
          href={customUrl}
          class="bg-purple flex md:hidden px-3 mt-3 w-full sm:px-7 h-[45px] rounded-md text-white hover:scale-[101%] transition-all duration-100 hover:shadow-md hover:drop-shadow-md items-center justify-center"
        >
          {customText}
        </a>
      )}
    </div>
  );
}

export default function VideoCard(el) {
  const App = <VideoCardComponent data={JSON.parse(el.dataset.data)} />;
  render(App, el);
}
